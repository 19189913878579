/* SEMANTIC OVERWRITE */
.ui.segment {
  border: 0px !important;
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
}
.ui.form input[type="checkbox"] {
  transform: scale(2);
  margin: 1em;
}
.ui.form select {
  height: 38px !important;
}
.ui.form input[type="file"] {
  border: 0px !important;
  box-shadow: none !important;
  font-size: 1.5em;
}
select option {
  font-size: 1em;
  padding: 1em 1.5em;
}
.margin1em {
  margin: 1em !important;
}
.height500px {
  height: 500px;
}
.bold {
  font-weight: bold;
}

.fa-facebook {
  background: #3b5998;
  color: white;
  padding: 9px 1px 5px 6px;
  font-size: 20px;
  width: 33px;
  text-align: center;
  text-decoration: none;
  border-radius: 50%;
  margin: 0px 7px;
}
.fa-whatsapp {
  background: #55acee;
  color: white;
  padding: 5px 0px 5px 7px;
  font-size: 23px;
  width: 33px;
  text-align: center;
  text-decoration: none;
  border-radius: 50%;
}
/*.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}*/

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

* {
  box-sizing: border-box;
}

/* GENERAL */
.full-width {
  width: 100%;
}
.min-width-300px {
  min-width: 300px;
}
.half-width {
  width: 50%;
}
.columns {
  display: flex;
  flex-direction: row;
  align-content: flex-start;
  align-items: flex-start;
  justify-content: space-between;
}
.columns .column-rows {
  display: flex;
  flex-direction: row;
  align-content: flex-start;
  align-items: flex-start;
  justify-content: flex-start;
  width: 45%;
}
.columns .column-cols {
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  align-items: flex-start;
  justify-content: flex-start;
  width: 45%;
}
.padding-top-bottom-20px {
  padding-top: 20px;
  padding-bottom: 20px;
}
.padding-bottom-30px {
  padding-bottom: 30px;
}
.font-size-2v5em {
  font-size: 2.5em !important;
}
.row-spacebetween {
  display: flex !important;
  justify-content: space-between !important;
}
.columns .column-cols .field,
.columns .column-rows .field {
  width: 100%;
}
.color-red {
  color: red;
}
.bold {
  font-weight: bold;
}
.hidden {
  display: none;
}
.hidden-important {
  display: none !important;
}

a.menu-item.active,
a.slide-menu-item.active {
  background-color: #fe7f34;
  font-weight: bold;
}

input.bhp-button:hover {
  background-color: #fe7f34 !important;
  color: #121212 !important;
}
a:hover {
  background-color: #fe7f34 !important;
  color: #121212 !important;
}
.noHover:hover {
  background-color: transparent !important;
  color: inherit !important;
}
.restrict-width {
  margin: auto;
  max-width: 1000px;
  width: 100%;
}
header,
footer,
.adds-header {
  background-color: rgb(57, 57, 57);
  height: auto;
  color: white;
}

.lista-dotari-text {
  align-items: flex-end !important;
}
.lista-dotari-icon {
  color: red !important;
  font-size: 2.5em !important;
  height: 1.25em !important;
}
.dotari-custom-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
  align-items: flex-start;
  justify-content: flex-start;
}
.dotari-custom-item {
  white-space: nowrap;
  width: 49%;
  padding: 0px;
  min-width: 300px;
}

/* START - - - ADD DETAILS PAGE - - - ADD DETAILS PAGE - - - START */

.add-details-main-div {
  width: 100%;
  display: flex;
  flex-direction: column;
  font-size: 1.2em;
  line-height: 1.3em;
}
.add-details-main-div .add-title {
  font-weight: bold;
  font-size: 1.8em;
  padding: 2em 0em;
}
.padding-top-1em {
  padding-top: 1em;
}
.padding1em0em {
  padding: 1em 0em;
}
.line-height-1v5em {
  line-height: 1.5em;
}
.line-height-1em {
  line-height: 1em;
}
.add-details-main-div .add-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.add-main-section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.add-details-main-div .add-image-box {
  display: block;
  margin-right: 4em;
}
.add-details-main-div .add-image-box .img-thumb {
  width: 21em;
}
.add-column {
  line-height: 1.6em;
}
.add-column .add-price {
  font-size: 1.8em;
  font-weight: bold;
}
.add-column .add-price-tva {
  padding: 0.7em 0em;
}

/* END - - - ADD DETAILS PAGE - - - ADD DETAILS PAGE - - - END */

/* START - - - HEADER SECTION - - - HEADER SECTION - - - START */

.slider-menu {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-items: center;
  justify-content: left;
  text-align: left;
  padding: 0px;
  z-index: 999;
  width: 100%;
  min-width: 140px;
  height: 100%;
  position: fixed;
  top: 0px;
  left: 0px;
  background-color: #f3f1ee;
  opacity: 0.9;
}

/* slider animations - start */
.slider-menu.hidden {
  left: -300px;
  display: none;
}
.slider-menu-slide-in {
  animation: slide-in 0.2s forwards ease-in-out;
  animation-iteration-count: 1;
}
.slider-menu-slide-out {
  animation: slide-out 0.2s forwards ease-in-out;
  animation-iteration-count: 1;
}
@keyframes slide-in {
  0% {
    left: -200px;
    top: 0px;
  }
  100% {
    left: 0px;
    top: 0px;
  }
}
@keyframes slide-out {
  0% {
    left: 0px;
    top: 0px;
  }
  100% {
    left: -300px;
    top: 0px;
  }
}
/* slider animations - end */

.slider-menu > .slide-menu-item {
  width: 100%;
  padding: 24px 0px;
  border-bottom: 1px solid gray;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  color: black;
  font-size: 1.5em;
}
.no-bottom-border {
  border: 0px !important;
}
header {
  display: flex;
  flex-direction: column;
  margin: 0px;
  height: 90px;
  padding: 0px 25px;
}
.admin-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.admin-header-logo {
  padding: 0px 20px;
  display: none;
}
.admin-header-icons {
  display: flex;
  justify-content: center;
  align-items: center;
}
.media-icons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-items: flex-start;
  justify-content: flex-start;
}

.header-middle-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-items: center;
  justify-items: center;
  color: var(--color-orange);
  cursor: pointer;
}
.header-middle-item img {
  width: 1.7em;
  margin-right: 0.7em;
}
.footer-column .header-middle-item img {
  width: 2.5em;
  margin-right: 0.7em;
}
.header-middle-item > i {
  font-size: 34px;
  padding-right: 7px;
}
.header-middle-item .phone-number {
  font-size: 1.5rem;
  cursor: pointer;
}

.menu-flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-items: center;
  justify-content: space-between;
}
.admin-header .menu-item,
.menu-flex-row .menu-item {
  padding: 18px 26px;
  border: 0px;
  height: 100%;
  color: white;
  text-decoration: none;
}
.menu-item.item-left {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.menu-item.item-right {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.menu-mini {
  display: none;
  border: 1px solid darkgray;
  border-radius: 7px;
  cursor: pointer;
}
.menu-mini > .mini-bars {
  width: 15px;
  height: 2px;
  background-color: cadetblue;
  margin: 4px 0;
}

/* END - - - HEADER SECTION - - - HEADER SECTION - - - END */

.admin-page-home-main-div {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0px;
}
.admin-page-home {
  padding: 20px;
  min-height: 70vh;
}
.admin-raport-box {
  border: 1px solid gray;
  border-radius: 4px;
  padding: 10px;
  color: #121212;
  text-decoration: none;
  font-size: 1.25em;
  padding: 7px 16px;
  background-color: #fe7f34;
}

/* START - - - Page HOME - - - Page HOME - - - START */

.home-page-main-div {
  display: flex;
  min-height: 75vh;
  flex-direction: column;
  margin: 0;
  width: 100%;
}
.header-welcome-msg {
  font-size: 1.5rem;
  padding-left: 0;
  line-height: 1em;
}
.adds-header-label {
  font-size: 30px;
  padding: 45px 25px;
}
.header-welcome-msg > .bhp-name,
.adds-header-label > .bhp-name {
  color: #fe7f34;
  padding-left: 7px;
}

.adds-main {
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-items: flex-start;
  justify-content: flex-start;
  /*-webkit-box-shadow: 2px 2px 10px 0 rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 2px 2px 10px 0 rgba(0, 0, 0, 0.5);
  box-shadow: 2px 2px 10px 0 rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 4px 2px 10px 0 rgba(0, 0, 0, 0.5);
  box-shadow: 2px 2px 10px 0 rgba(0, 0, 0, 0.5);*/
}
.add-list-view {
  width: 100%;
  padding: 19px 26px 19px 26px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-items: flex-start;
  justify-content: flex-start;
}
.badge-new-car {
  background-image: url("https://cdn2.iconfinder.com/data/icons/picons-basic-2/57/basic2-277_new_badge-512.png");
  background-repeat: no-repeat;
  background-size: 40px;
  background-position: top right;
}
.lighter-background-color {
  background-color: #ffffff;
}
.darker-background-color {
  background-color: #f7f6f6;
}
.add-list-view .add-image-box {
  width: 20%;
}
.add-list-view .add-image-box img {
  width: 100%;
  border: 1px solid lightgray;
}
.add-list-view > .add-row {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: flex-start;
  justify-items: flex-start;
  justify-content: space-between;
}

.add-list-view > .add-title {
  font-size: 18px;
  font-weight: bold;
  width: 100%;
  padding: 10px 0px 12px 0px;
}
.add-row .add-details-box {
  font-size: 12px;
  width: 78%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: flex-start;
  justify-items: flex-start;
  justify-content: space-between;
}
.add-row .add-details-box .details-column {
  width: 32%;
}
.add-row .add-details-box .row-column {
  width: 60%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: flex-start;
  justify-items: flex-start;
  justify-content: flex-start;
}
.details-column div {
  padding: 3px 0px;
}
.add-row .add-price-box {
  text-align: right;
}
.add-price-box .add-price {
  font-size: 20px;
  font-weight: bold;
}
.add-price-box div {
  padding: 3px 0px;
}

/* END - - - Page HOME - - - Page HOME - - - END */

/* START - - - Page SERVICES - - - Page SERVICES - - - START */

.services-page-main-div {
  display: flex;
  min-height: 75vh;
  flex-direction: column;
  margin: 0;
  width: 100%;
}

/* END - - - Page SERVICES - - - Page SERVICES - - - END */

/* START - - - Page CONTACT - - - Page CONTACT - - - START */

.contact-page-main-div {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0px;
}
.contact-page {
  background-color: #fbfbf8;
  padding: 4vh 4vh 0vh 4vh;
}
.contact-map {
  width: 100%;
}
.contact-map-item {
  width: 100%;
  min-height: 270px;
  border: 0px solid gray;
  padding: 15px;
  border-radius: 5px;
  background-color: white;
}

.contact-form-btn {
  text-align: right;
  width: 100%;
}
.contact-form-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: flex-start;
  justify-items: flex-start;
  justify-content: space-between;
  margin: 30px 0px;
}
.contact-vertical-form {
  width: 60%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: flex-start;
  justify-items: flex-start;
  justify-content: flex-start;
}
.contact-form-container .business-info {
  width: 45%;
}
.contact-vertical-form label {
  font-size: 1rem;
  padding: 0px;
}
.vertical-form-message,
.vertical-form-input {
  font-size: 1rem;
  width: 100%;
  padding: 7px 12px;
  border: 1px solid #dcdcdc;
  color: #333;
  margin: 5px 0px;
}
.contact-vertical-form .bhp-button {
  margin-top: 10px;
}
.contact-news-header {
  font-size: 24px;
  font-weight: bold;
}
.signup-newsletter {
  margin: 5px 0px 20px 0px;
  color: #333;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: center;
  justify-items: center;
  justify-content: space-between;
}
.signup-newsletter input {
  width: 33%;
  font-size: 1em;
}

/* END - - - Page CONTACT - - - Page CONTACT - - - END */

/* START - - - Page AUTHENTICATION - - - Page AUTH - - - START */
.image-gallery-fullscreen-button_old {
  margin-top: -64px !important;
  width: 100%;
  height: 100%;
  position: relative !important;
  top: 200px !important;
}
.img-thumb {
  width: 200px;
}
.img-thumb-mini-gallery {
  width: 100% !important;
  height: 100% !important;
}
.img-thumb-mini-gallery-thumb {
  width: 100px !important;
}
.admin-img-thumb-div {
  margin-top: 3em;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  justify-items: flex-start;
  flex-wrap: wrap;
}
.auth-page-main-div {
  width: 100%;
}
.auth-page {
  padding: 2.5em 0em;
}
.header-center {
  text-align: center;
  padding: 20px 0px;
  font-size: 1.5em;
}
.vertical-narrow {
  width: 34em;
  margin: auto;
  padding: 10px;
}
.label-input-set-vertical {
  width: 100%;
  margin: 4px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  justify-content: center;
}
.label-input-set-vertical > label {
  width: 100%;
  margin: 2px 0px;
}
.label-input-set-vertical > input,
.label-input-set-vertical > button {
  width: 100%;
  padding: 7px 12px;
  margin-top: 5px;
  margin-bottom: 5px;
  border-radius: 3px;
  border: 1px;
  border-color: rgb(212, 215, 220);
  border-style: solid;
}
.bhp-button {
  background-color: #fe7f34;
  color: #fcfcfc;
  letter-spacing: 1;
  width: 100%;
  cursor: pointer;
  align-self: center;
  text-align: center;
  border-radius: 3px;
  font-size: 1.5em;
}

.forgot-password {
  text-align: center;
  padding: 10px;
}
.forgot-pass {
  color: #365ec2;
  margin: 4px;
  cursor: pointer;
}
.flex-row-centered {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
}

/* END - - - Page AUTHETIFICATION - - - Page AUTHETIFICATION - - - END */

/* START - - - FOOTER SECTION - - - FOOTER SECTION - - - START */
a {
  color: inherit;
  text-decoration: none;
}
.footer-section-main-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  justify-content: center;
  margin-top: 2em;
  padding: 26px 0px;
  width: 100%;
  background-color: rgb(57, 57, 57);
}
.footer-body {
  font-size: 11px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-items: center;
  justify-content: space-between;
  height: auto;
}
.footer-column {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-items: center;
  justify-content: left;
  margin: 5px 0px;
  width: 100%;
}
.footer-column-element {
  padding: 5px 34px;
  color: #efefef;
  width: 100%;
  text-align: left;
  text-decoration: none;
  font-size: 1rem;
}
.header-logo-website {
  width: 134px;
  height: 53px;
}

.image-gallery-thumbnails .image-gallery-thumbnails-container {
  white-space: pre-wrap !important;
}

/* START - - - RESPONSIVENESS MAGIC - - - RESPONSIVENESS MAGIC - - - START */

@media screen and (max-width: 575px) {
  .hide-on-mobile {
    display: none !important;
  }
  header {
    /* display: block; */
    flex-direction: column;
    margin: 0px;
    height: 70px;
    padding: 0px;
  }
  .contact-page {
    padding: 20px;
  }
  .adds {
    flex-direction: column;
    padding: 0px;
  }
  .admin-header-logo {
    display: block;
  }
  .header-logo {
    display: none;
  }
  .header-welcome-msg {
    display: block;
    margin: auto;
    padding-right: 42px;
  }
  .menu-flex-row {
    justify-content: flex-start;
  }

  .menu-mini {
    display: block;
  }
  .menu-display {
    display: none;
  }

  .adds-header,
  .slideshow-container {
    display: none;
  }
  .add-list-view > .add-row {
    flex-direction: column;
    align-items: flex-end;
  }
  .add-row .add-details-box {
    width: 100%;
    padding: 10px 0px;
  }
  .add-list-view > .add-title {
    padding: 10px 50px 12px 0px;
  }
  .add-list-view .add-image-box {
    width: 100%;
  }
  .contact-map-item {
    padding: 8px;
  }
  .vertical-narrow {
    width: 100%;
  }
  .footer-body {
    flex-direction: column;
  }
  .footer-column-element {
    font-size: 1.25rem;
    text-align: center;
  }
  .contact-form-container .business-info {
    width: 100%;
  }
  .contact-vertical-form {
    width: 100%;
    margin-top: 30px;
  }
  .add-row .add-details-box .row-column {
    width: 70%;
  }

  .add-details-main-div {
    padding: 1em;
    font-size: 1.1em;
  }
  .add-details-main-div .add-title {
    padding: 0.2em 0em;
    line-height: 1.2em;
  }
  .add-main-section {
    flex-direction: column;
  }
  .add-details-main-div .add-row .add-row {
    flex-direction: row;
    padding: 1em 0em;
  }
}

/* END - - - RESPONSIVENESS MAGIC - - - RESPONSIVENESS MAGIC - - - END */
