/* Slideshow container */
.slideshow-container {
  position: relative;
  margin: 0px auto;
  color: #000000;
  font-size: 1.8em;
  font-weight: bold;
  background-color: rgb(57, 57, 57) !important;
}
.none {
  display: none;
}
.slide-img {
  background-repeat: no-repeat;
  background-position: center;
  background-size: 1000px;
}
.center {
  text-align: center;
  top: 0em;
  left: 0.5em;
  position: absolute;
}
.width100 {
  width: 100%;
  background-color: transparent;
}

.slideshow-title-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  top: -100px;
  left: 0px;
  position: relative;
}
.slideshow-title-container .adjustment-div {
  width: calc((100% - 1000px) / 2);
}
.slideshow-title-container .slideshow-title {
  opacity: 0.9;
  background-color: #fff;
  padding: 1em 1.5em;
  border-radius: 0.15em;
}

/* Number text (1/3 etc) */
.numbertext {
  font-size: 0.8em;
  padding: 1em 1em;
  position: absolute;
  top: 0;
}

/* The dots/bullets/indicators */
.dot {
  height: 15px;
  width: 15px;
  margin: 0 2px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
  cursor: pointer;
}

/* Fading animation */
.fade {
  -webkit-animation-name: fade;
  -webkit-animation-duration: 2.5s;
  animation-name: fade;
  animation-duration: 2.5s;
}

@-webkit-keyframes fade {
  from {
    opacity: 0.4;
  }
  to {
    opacity: 1;
  }
}

@keyframes fade {
  from {
    opacity: 0.4;
  }
  to {
    opacity: 1;
  }
}

/* On smaller screens, decrease text size */
@media only screen and (max-width: 300px) {
  .text {
    font-size: 11px;
  }
}
